import {lazy} from "react";

const _00_Document_and_Record_Control = lazy(() => import('./00_Document_and_Record_Control/Main'/* webpackChunkName:"00_Document_and_Record_Control" */));

const _01_Project_Plan = lazy(() => import('./01_Project_Plan/Main'/* webpackChunkName:"01_Project_Plan" */));

const _02_IMS_Policies = lazy(() => import('./02_IMS_Policies/Main'/* webpackChunkName:"02_IMS_Policies" */));

// const _03_Integrated_Managemet_System_Manual = lazy(() => import('./03_Integrated_Managemet_System_Manual/Main'/* webpackChunkName:"03_Integrated_Managemet_System_Manual" */));

// const _04_Procedure_Determining_Context_Organization_Interested_Parties = lazy(() => import('./04_Procedure_Determining_Context_Organization_Interested_Parties/Main'/* webpackChunkName:"04_Procedure_Determining_Context_Organization_Interested_Parties" */));

const _05_Training = lazy(() => import('./05_Training/Main'/* webpackChunkName:"05_Training" */));

const _06_Communication_And_Participation = lazy(() => import('./06_Communication_And_Participation/Main'/* webpackChunkName:"06_Communication_And_Participation" */));

// const _07_Procedure_for_Addressing_Risks_and_Opportunities = lazy(() => import('./07_Procedure_for_Addressing_Risks_and_Opportunities/Main'/* webpackChunkName:"07_Procedure_for_Addressing_Risks_and_Opportunities" */));

const _08_Environmental_Aspects = lazy(() => import('./08_Environmental_Aspects/Main'/* webpackChunkName:"08_Environmental_Aspects" */));

const _09_HIRA_And_Opportunities = lazy(() => import('./09_HIRA_And_Opportunities/Main'/* webpackChunkName:"09_HIRA_And_Opportunities" */));

// const _10_Sales_Procedure = lazy(() => import('./10_Sales_Procedure/Main'/* webpackChunkName:"10_Sales_Procedure" */));

// const _11_Procedure_for_Design_and_Development = lazy(() => import('./11_Procedure_for_Design_and_Development/Main'/* webpackChunkName:"11_Procedure_for_Design_and_Development" */));

// const _12_Procedure_for_Purchasing_and_Evaluation_of_Suppliers = lazy(() => import('./12_Procedure_for_Purchasing_and_Evaluation_of_Suppliers/Main'/* webpackChunkName:"12_Procedure_for_Purchasing_and_Evaluation_of_Suppliers" */));

// const _13_Procedure_for_Production_Service_Provision = lazy(() => import('./13_Procedure_for_Production_Service_Provision/Main'/* webpackChunkName:"13_Procedure_for_Production_Service_Provision" */));

// const _14_Warehousing_Procedure = lazy(() => import('./14_Warehousing_Procedure/Main'/* webpackChunkName:"14_Warehousing_Procedure" */));

// const _15_Procedure_for_OH_and_S_Change_Management = lazy(() => import('./15_Procedure_for_OH/Main'/* webpackChunkName:"15_Procedure_for_OH" */));

const _16_Emergency_Preparedness = lazy(() => import('./16_Emergency_Preparedness/Main'/* webpackChunkName:"16_Emergency_Preparedness" */));

const _17_Incident_Investigation = lazy(() => import('./17_Incident_Investigation/Main'/* webpackChunkName:"17_Incident_Investigation" */));

const _18_Nonconformance_Management = lazy(() => import('./18_Nonconformance_Management/Main'/* webpackChunkName:"18_Nonconformance_Management" */));

const _19_Maintenance = lazy(() => import('./19_Maintenance/Main'/* webpackChunkName:"19_Maintenance" */));

// const _20_Procedure_for_Measuring_Customer_Satisfaction = lazy(() => import('./20_Procedure_for_Measuring_Customer_Satisfaction/Main'/* webpackChunkName:"20_Procedure_for_Measuring_Customer_Satisfaction" */));

const _21_Internal_Audit = lazy(() => import('./21_Internal_Audit/Main'/* webpackChunkName:"21_Internal_Audit" */));

const Inspections = lazy(() => import('./Inspections/Main'/* webpackChunkName:"Inspections" */));

// const _22_Procedure_for_Continual_Improvement = lazy(() => import('./22_Procedure_for_Continual_Improvement/Main'/* webpackChunkName:"22_Procedure_for_Continual_Improvement" */));

// const _23_Procedure_for_Management_Review = lazy(() => import('./23_Procedure_for_Management_Review/Main'/* webpackChunkName:"23_Procedure_for_Management_Review" */));

// const _24_Asset_Management = lazy(() => import('./24_Asset_Management/Main'/* webpackChunkName:"24_Asset_Management" */));

const _13_2_3_Hazardous_Chemical_Substances = lazy(() => import('./13_2_3_Hazardous_Chemical_Substances/Main'/* webpackChunkName:"13_2_3_Hazardous_Chemical_Substances" */));

const modules = {
	_00_Document_and_Record_Control,
	_01_Project_Plan,
	_02_IMS_Policies,
////_03_Integrated_Managemet_System_Manual,
////_04_Procedure_Determining_Context_Organization_Interested_Parties,
	_05_Training,
	_06_Communication_And_Participation,
////_07_Procedure_for_Addressing_Risks_and_Opportunities,
//    _08_Environmental_Aspects,
	_09_HIRA_And_Opportunities,
////_10_Sales_Procedure,
////_11_Procedure_for_Design_and_Development,
////_12_Procedure_for_Purchasing_and_Evaluation_of_Suppliers,
////_13_Procedure_for_Production_Service_Provision,
////_14_Warehousing_Procedure,
////_15_Procedure_for_OH_and_S_Change_Management,
	_16_Emergency_Preparedness,
	_17_Incident_Investigation,
	_18_Nonconformance_Management,
	_19_Maintenance,
//  _20_Procedure_for_Measuring_Customer_Satisfaction,
	_21_Internal_Audit,
	Inspections,
//  _22_Procedure_for_Continual_Improvement,
//  _23_Procedure_for_Management_Review,

	_13_2_3_Hazardous_Chemical_Substances
};

export {
	modules
};
