
//<editor-fold desc="chemical_sds_details_fragment">
export const chemical_sds_details_fragment = `
    id
    created_at
    
    product{
        id
        name
    }
    name
    common_names
    chemical_name
    empirical_formula
    un_num
    category
    date_identified
    application_or_use
    
    supplier_list{
      id
      manufacturer
      address
      phone
      email
      fax
      website
    }
    
    emergency_phone_nums
    risk_level
    more_product_info
    
    composition_or_information_on_ingredients
    
    hazards_identification
    
    first_aid_measures_and_precautions
    
    firefighting_measures
    
    accidental_release_measures
    
    handling_and_storage_requirements
    
    exposure_controls_or_personal_protection
    
    physical_and_chemical_properties
    
    stability_and_reactivity
    
    toxicological_info
    
    ecological_info
    
    disposal_considerations
    
    transport_info
    
    regulatory_info
    
    other_info
    
    doc
`;
//</editor-fold>

