import {employee_personal_details_fragment} from "graphql/Fragments";

//<editor-fold desc="hira_swp_details_fragment">
export const hira_swp_details_fragment = `
    id
    created_at
		date_created
		expiry
		category
		location
		work_task
		sign_sheet_notes
		notes
		doc
		steps_to_perform_task_list
		training_requirement_list
		removed
    department{
      id
      name
    }
		person_approving_procedure{
			${employee_personal_details_fragment}
		}
		potential_hazard_and_possible_cause_list{
			id
			swp_id
			cause
			hazard
			rating
		}
		ppe_required_list{
			id
			ppe{
				id
				name
				profile_pic
			}
		}
		sign_sheet_employee_list{
			id
			swp_id
			signature
			employee{
				${employee_personal_details_fragment}
			}
		}
`;
//</editor-fold>
