import React from "react";
import {observer} from 'mobx-react';
import {computed, observable} from 'mobx';

import {ClearableInput} from './';

import ASelectInput from './ASelectInput';

@observer
class SelectInput extends ASelectInput {

    @observable
    state = {
        lists: {
            list: []
        },
        props: null
    };
    
    constructor(props) {
        super(props);
    }
    
    async componentDidMount() {
        let {className = ''} = this.props;
        className += ` SelectContractorQuestionnaireTemplateInput`;
        
        const res = await onSelectLoad();
        this.state.lists.list = res.distinct();
        
        this.state.props = {
            ref: "input",
            key: new Date().getTime(),
            name: "questionnaire_template",
            type: "select",
            placeholder: "Questionnaire Template...",
            values: this.list,
            returnValue: true,
            add: false,
            ...this.props,
            defaultValue: this.selectedItem,
            className
        };
    }

    @computed
    get selectedItem() {
        const {defaultValue} = this.props;
        if(defaultValue) {
            if(defaultValue.label && defaultValue.value) {
                return defaultValue;
            }
            let {name, data} = defaultValue;
            const label = data ? data.name : name;
            return {value: defaultValue, label};
        }
        return null;
    }

    render() {
        const {props} = this.state;
        if(!props) return null;

        return <ClearableInput {...props}/>;
    }
}

function onSelectLoad() {
    return neonBinding.events.emit("plugin_sheq_assist/contractors/questionnaires/templates/list").then(res => {
        return res.map(value => {
            const {name, desc, category, review_date, doc_num, doc} = value.data;
            const label = <div className="flex">
                <span className="margin-0">{doc_num} {name}</span>
                <b className="margin-0 fixed-flex">PDF</b>
            </div>;
            return {label, value};
        });
    });
}

export default SelectInput;
